import React, { useContext, createContext, useState, useEffect, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { serverAddress } from "../config";
import axios from "axios";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { baseURL } from "../api";
const Contaxt = createContext();

function GlobalData({ children }) {
  const formname = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState();
  const [handleClickOpen, setHandleClickOpen] = useState(null);
  const [modalEditData, setModalEditData] = useState({});
  const [openModalData, setOpenModalData] = useState({});
  const [isOpenModalChildData, setIsOpenModalChildData] = useState(false);
  const [openModalChildData, setOpenModalChildData] = useState([]);
  const [openModalDataActive, setopenModalDataActive] = useState({});
  const [modalActionTypeAndID, setModalActionTypeAndID] = useState({});
  const [textValue, setTextValue] = useState({});
  const [fetchDataHandleDataGrid, setFetchDataHandleDataGrid] = useState({});
  const [editApi, setEditApi] = useState(null);
  const [modalPrimaryKey, setModalPrimaryKry] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [globalFormData, setGlobalFormData] = useState(null);
  const [freeFormInitState, setFreeFormInitState] = useState({});
  const [mainFormData, setmainFormData] = useState([]);
  const [defaultTableSummaryData, setDefaultTableSummaryData] = useState([]);
  const [globalvariables, setglobalvariables] = useState({});
  const [sideBarActive, setsideBarActive] = useState("");
  const [sideBarStyle, setsideBarStyle] = useState("");
  const [isPageLoading, setisPageLoading] = useState(true);
  const [isoverLayDataLoading, setisoverLayDataLoading] = useState(true);
  const [autoCompleteAPICheck, setAutoCompleteAPICheck] = useState([]);
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [freeFormToDefault, setFreeFormToDefault] = useState(false);
  const [globalReloadTriggred, setGlobalReloadTriggered] = useState(false);

  const [freeFromtableRowDataStore, setFreeFromTableRowDataStore] = useState([]);
  const [formData, setFormData] = useState();
  const [isAlertPopup, setIsAlertPopup] = useState(true);
  const [overlaySplit, setOverLaySplit] = useState(false);
  const [overlaySplitOldData, setOverLaySplitOldData] = useState(true);
  const [overLayDataFetchApi, setOverLayDataFetchApi] = useState(null);
  const [defaultTableSummaryFeild, setdefaultTableSummaryFeild] = useState({});
  const [nestedDialog, setNestedDialog] = useState(false);

  const [openDocumentSelect, setOpenDocumentSelect] = useState(false);
  const [multiDocumentSelectData, setMultiDocumentSelectData] = useState([]);
  const [multiDocumentSelectDataForTable, setmultiDocumentSelectDataForTable] = useState([]);

  const [isDialogDrawerOpen, setDialogDrawerOpen] = useState(false);
  const [varCustomDialogDataOldData, setvarCustomDialogDataOldData] = useState({});
  
  const [varCustomDialogData,setVarCustomDialogData] = useState([])
  const [varCustomDialogTitle,setVarCustomDialogTitle] = useState('')
  const [varCutomDialogApi,setvarCutomDialogApi] = useState('')
  // const [ isDialogDrawerOpen,setDialogDrawerOpen] = useState(true)

  useEffect(() => {
    if (!open) {
      setEditApi(null);
      setModalEditData({});
    }
  }, [open]);

  const parseURL = urlString => {
    try {
      // Find the index of "?" in the URL
      const queryIndex = urlString.indexOf("&");

      // Extract the pathname
      const pathname = queryIndex !== -1 ? urlString.slice(0, queryIndex) : urlString;

      // Extract the search  query
      const searchQuery = queryIndex !== -1 ? urlString.slice(queryIndex) : "";

      // Return the results
      return { pathname, searchQuery };
    } catch (error) {
      // Handle invalid URLs
      console.error("Invalid URL:", urlString);
      return null;
    }
  };

  const urlCapture = window.location.pathname + window.location.search;
  const route = formname.pathname + formname.search;

  function fetchFormaData1() {
    // const url = "/form/get/customerEdit&id=CUS-0007";

    const apiUrl =
      route === undefined || route !== urlCapture
        ? `${baseURL}${urlCapture}`
        : `${baseURL}${route}`;
    const result = parseURL(apiUrl);
    // if (!isPageLoading) {
    //   setisPageLoading(true)
    // }
    if (overlaySplit) {
      setisoverLayDataLoading(true);
    } else {
      setisPageLoading(true);
    }
    axios
      .get(
        overlaySplit
          ? serverAddress + overLayDataFetchApi
          : result.searchQuery.startsWith("&doc")
          ? result.pathname + result.searchQuery
          : result.pathname,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(result => {

        setFormData(result?.data?.data ? result?.data?.data : result?.data);
        setmainFormData(result?.data?.data ? result?.data?.data : result?.data);

        if (!overlaySplit) {
          setOverLaySplitOldData(result?.data?.data ? result?.data?.data : result?.data);
        }
        if (!isDialogDrawerOpen) {
          setvarCustomDialogDataOldData(result?.data?.data ? result?.data?.data : result?.data);
        }
        if (overlaySplit) {
          setisoverLayDataLoading(false);
        } else {
          setisPageLoading(false);

          // setisPageLoading(true)
        }

      })
      .catch(error => {
        // Handle the error
        if (error?.response?.status == "401") {
          // console.error(error?.response.status, "error456");
          // LogoutOnError();
          setisPageLoading(true);
        }
        if (error?.response?.status == "400") {
          setIsAlertPopup({ open: true, msg: error?.response?.data?.metadata.msg });
        }
      });
  }

  useEffect(() => {
    if (token) {
      fetchFormaData1();
    }
  }, [route, urlCapture, token]);

  useEffect(() => {
    if (token && overlaySplit) {
      fetchFormaData1();
    }
  }, [overlaySplit]);

  useEffect(() => {
    if (editApi && open) {
      // console.log(editApi,modalPrimaryKey, "editApi");

      axios
        .get(`${serverAddress}${editApi}`, {
          headers: {
            Authorization: `Bearer ${token}`
            // Other headers if needed
          }
        })
        .then(response => setModalEditData(response.data.data.mainrecord))
        .catch(err => console.log(err));
    }
  }, [editApi, open]);

  function parseJsonString(jsonString) {
    try {
      const jsonObject = JSON.parse(jsonString);
      return jsonObject;
    } catch (error) {
      console.error("Failed to parse JSON string:", error);
      return null;
    }
  }
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
      // /record/get/sys_template/default

      axios
        .get(`${serverAddress}/record/get/sys_template/default`, {
          headers: {
            Authorization: `Bearer ${token}`
            // Other headers if needed
          }
        })
        .then(response => {
          setsideBarStyle(parseJsonString(response.data.data.mainrecord.sTemplateSections));
        })
        .catch(err => {
          localStorage.clear();
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
    if (userData) {
      setUserData(JSON.parse(userData));
    } else {
      navigate("/login");
    }
    const activeMenu = localStorage.getItem("activeSideItems");
    setsideBarActive(activeMenu);
  }, []);

  // https://www.youtube.com/feed/trending?bp=6gQJRkVleHBsb3Jl

  function LogoutOnError() {
    localStorage.clear();
    navigate("/login");
  }

  function setsideBarActiveBtn(id) {
    localStorage.setItem("activeSideItems", id);
    getsideBarActiveBtn();
  }
  function getsideBarActiveBtn() {
    const activeMenu = localStorage.getItem("activeSideItems");
    setsideBarActive(activeMenu);
  }
  const [theamStyle, setTheamStyle] = React.useState({});

  function parseJsonString3(jsonString) {
    try {
      const jsonObject = JSON.parse(jsonString);
      return jsonObject;
    } catch (error) {
      console.error("Failed to parse JSON string:", error);
      return null;
    }
  }

  useEffect(() => {
    // alert('hh')
    axios
      .get(`${serverAddress}/record/get/sys_template/default`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
          // Other headers if needed
        }
      })
      .then(response => {
        // alert(JSON.stringify('theme'))
        setsideBarStyle(parseJsonString(response.data.data.mainrecord.sTemplateSections));
        const theme = createTheme(parseJsonString3(response.data.data.mainrecord.sTheme));
        setTheamStyle(theme);
        // setsideBarStyle(parseJsonString(response.data.data.mainrecord.sTemplateSections));
      })
      // .then(response =>)
      .catch(err => {
        localStorage.clear();
        navigate("/login");
      });
  }, [token]);

  return (
    <Contaxt.Provider
      value={{
        editApi,
        globalvariables,
        formData,
        setFormData,
        setglobalvariables,
        setEditApi,
        fetchDataHandleDataGrid,
        setFetchDataHandleDataGrid,
        token,
        setToken,
        userData,
        setUserData,
        modalActionTypeAndID,
        setModalActionTypeAndID,
        isoverLayDataLoading,
        setisoverLayDataLoading,
        LogoutOnError,
        handleClickOpen,
        setHandleClickOpen,
        modalEditData,
        setModalEditData,
        defaultTableSummaryData,
        setDefaultTableSummaryData,
        textValue,
        isAlertPopup,
        setIsAlertPopup,
        defaultTableSummaryFeild,
        setdefaultTableSummaryFeild,
        setTextValue,
        globalFormData,
        setGlobalFormData,
        theamStyle,
        modalPrimaryKey,
        isDialogDrawerOpen,
        setDialogDrawerOpen,
        setModalPrimaryKry,
        setsideBarStyle,varCutomDialogApi,setvarCutomDialogApi,
        openModalData,
        freeFromtableRowDataStore,
        setFreeFromTableRowDataStore,
        setOpenModalData,
        nestedDialog,
        setNestedDialog,
        open,
        fetchFormaData1,
        isOpenModalChildData,
        setIsOpenModalChildData,
        setOpen,
        setOverLaySplit,
        overlaySplit,
        freeFormInitState,
        overLayDataFetchApi,
        setOverLayDataFetchApi,
        setFreeFormInitState,
        setmultiDocumentSelectDataForTable,
        multiDocumentSelectDataForTable,
        mainFormData,
        setmainFormData,
        sideBarStyle,
        overlaySplitOldData,
        setOverLaySplitOldData,
        sideBarActive,
        isPageLoading,
        setisPageLoading,
        openDocumentSelect,
        setOpenDocumentSelect,
        setsideBarActive,setVarCustomDialogTitle,varCustomDialogTitle,
        openModalDataActive,
        setopenModalDataActive,
        setsideBarActiveBtn,
        openModalChildData,varCustomDialogData,setVarCustomDialogData,
        setOpenModalChildData,
        multiDocumentSelectData,
        setMultiDocumentSelectData,
        autoCompleteAPICheck, setAutoCompleteAPICheck,
        autoCompleteData, setAutoCompleteData,
        globalReloadTriggred, setGlobalReloadTriggered,
        freeFormToDefault, setFreeFormToDefault
      }}
    >
      <ThemeProvider theme={theamStyle}>{children}</ThemeProvider>
      {/* </> */}
    </Contaxt.Provider>
  );
}
export const Global_Data = () => useContext(Contaxt);

export default memo(GlobalData);
