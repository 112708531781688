import * as React from "react";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../api";
import axios from "axios";

import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Global_Data } from "../../globalData/GlobalData";
import Vartext from "../../component/VARTEXT/Vartext";
import { sprintf } from "sprintf-js";
import VARCUSTOMHTML from "../../component/VARCUSTOMHTML/VARCUSTOMHTML";
import VarDateTime from "../../component/VARDATETIME/VarDateTime";
import VARNUMBER from "../../component/VARNUMBER/VARNUMBER";

export default function ColumnTypesTable({
  varValue,
  setVarValue,
  format,
  formdata,
  formDetails,
  recordData,
  renderComponent
}) {
  //const [rows, setRows] = React.useState("");
  const { token, textValue, varCutomDialogApi } = Global_Data();
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const [tableRecords, setTableRecords] = useState([]);


  function getQueryParams(url) {
    if(url){
      const urlObj = new URL(url, baseURL);
      const params = urlObj.searchParams;
      const queryParams = {};
      params.forEach((value, key) => {
          queryParams[key] = value;
      });
      
      return queryParams;
    }
  }
  
  function populateQueryParams(url, options) {
    return url.replace(/\[([^\]]+)\]/g, (match, key) => options[key] || match);
  }

  const fetchData = uri => {
    if(!uri.includes('{')){
      axios
      .get(baseURL + uri, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      })
      .then(response => {
        // setRows(true);

        setTableRecords(response.data.data.records);
        // if (setVarValue) {
        //   setVarValue(response.data.data.records);
        // }
        // response.data.map((row, index) => row["id"] = row[formDetails.sPrimaryKey] );
        // setRows(response.data);
      })
      .catch(error => console.log(error));
    }
  };
  function replacePlaceholders(uri, data) {
    const regex = /{([^}]+)}/g;
    const replacedUri = uri.replace(regex, (match, key) => {
      return key in data ? data[key] : match;
    });
    return replacedUri;
  }

  const url = window.location.href;

  // Create a new URL object
  const urlObj = new URL(url);

  // Use URLSearchParams to get the value of the 'id' parameter
  const id = urlObj.searchParams.get("id");
  useEffect(() => {
    // alert(JSON.stringify(formdata.component.defaultLoad.sDataSource));
    if (formdata.component.defaultLoad.bEnabled) {
      if(varCutomDialogApi){
        fetchData(populateQueryParams(formdata.component.defaultLoad.sDataSource, getQueryParams(varCutomDialogApi)));
      } else {
        fetchData(formdata.component.defaultLoad.sDataSource);
      }
    } else if (formdata.data.bCascade) {
      // console.log('kkk');

      fetchData(
        replacePlaceholders(
          formdata.data.sDataSource,
          textValue[formdata?.data?.sDataAware?.replace(/[{}]/g, "")]
            ? textValue
            : { [formdata?.data?.sDataAware?.replace(/[{}]/g, id)]: "" }
        )
      );
    }
  }, [formdata, textValue[formdata?.data?.sDataAware?.replace(/[{}]/g, "")]]);

  /* useEffect(() => {
    axios
    .get(baseURL+formdata.data.sDataSource)
    .then((response) => {
    setRows(true);
    console.log(response.data);
    response.data.map((row, index) => row["id"] = row[formDetails.sPrimaryKey] );
    setRows(response.data);
    });
    }, [rows.id]);

    function priceRow(qty, unit) {
        return qty * unit;
      }
      
      function createRow(col_items1, col_qty, col_rate, col_disc) {
        const col_amount = priceRow(col_qty, col_rate) * col_disc;
        return { col_items1, col_qty, col_rate, col_disc, col_amount };
      }
      
      function subtotal(items) {
        return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
      }
      
      const rows = [
        createRow('Paperclips (Box)', 100, 1.15, 0.10),
        createRow('Paper (Case)', 10, 45.99, 0.10),
        createRow('Waste Basket', 2, 17.99, 0.10),
      ];
*/

  if (recordData?.metadata?.status === "OK") {
    let finaldata = [];
    let finaldata1 = [];
    let tbrecs = recordData?.data[1]?.tablerecords;

    //get each table record
    /*tableRecords.forEach(element => {
        if (element?.sInputTableName === formdata?.component?.sName) {
            setTableRecords({element});
        }
    });*/

    tbrecs?.forEach(item => {
      if (item?.sInputTableName === formdata?.component?.sName) {
        tableRecords.push(item);
      }
    });
  }

  const columns = [];
  formdata?.child?.forEach((item, index) => {
    item.inputtable?.bHidden != 1 &&
      columns.push({
        field: item.inputtable?.sColumnID,
        headerName: item.inputtable?.sHeader,
        width: item.inputtable?.iWidth,
        editable: item.inputtable?.bEditable,
        sortable: item.inputtable?.iOrder,
        props: item.inputtable?.sProps,
        component: item.component
      });
  });

  formdata?.fixcolumns?.forEach((item, index) => {
    columns.push({
      field: item?.sColumnID,
      headerName: item?.sHeader,
      width: item?.iWidth,
      editable: item?.bEditable,
      sortable: item?.bSortable,
      props: item?.sProps
    });
  });
  // const [colID,setcolID]=useState(0)

  function getKeyByValue(obj, value) {
    for (let key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  }
  

  return (
    <>
      {/* {JSON.stringify(tableRecords)} */}
    

      {/* {JSON.stringify(formdata.data)} */}
      {/* {JSON.stringify(formdata.component.sName)} */}
      <TableContainer component={Box} {...formdata?.component?.sProps}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              {columns?.map(col => (
                <TableCell align="center">{col?.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {tableRecords?.map(row => ( */}
            {/* { renderComponent(columns)} */}

            {/* {JSON.stringify(tableRecords)} */}
            {tableRecords.map((record, ind) => (
              <TableRow>
                {(function () {
                  var colID = 0;
                  return formdata?.child.map((child, i) => {
                    colID = colID + 1;
                    return (
                      child.inputtable?.bHidden != 1 && (
                        <>
                          <TableCell style={{textAlign: "center"}}>
                            {/* {tableRecords[ind]?.[child.component.sName]} */}

                            {child.component.sType == "VARCUSTOMHTML" && (
                              <VARCUSTOMHTML
                                data={child}
                                format={format}
                                varValue={varValue}
                                id={
                                  formdata.component.sName +
                                  "-" +
                                  child.component.sName +
                                  "-" +
                                  colID +
                                  "-" +
                                  (ind + 1)
                                }
                                value={tableRecords[ind]?.[child.component.sName] || tableRecords[ind]?.[getKeyByValue(formdata.component.defaultLoad.sMapping, child.inputtable.sColumnID)]}
                              />
                            )}
                            {child.component.sType == "VARTEXT" && (
                              <VartextComp
                                data={child}
                                id={
                                  formdata.component.sName +
                                  "-" +
                                  child.component.sName +
                                  "-" +
                                  colID +
                                  "-" +
                                  (ind + 1)
                                }
                                value={tableRecords[ind]?.[child.component.sName] || tableRecords[ind]?.[getKeyByValue(formdata.component.defaultLoad.sMapping, child.inputtable.sColumnID)]}
                                format={format}
                                varValue={varValue}
                              />
                            )}

                            {child.component.sType == "VARDATETIME" && (
                              <>
                              {/* {tableRecords[ind]?.[child.component.sName]} */}
                              <VarDateTime data={child} value={tableRecords[ind]?.[child.component.sName] || tableRecords[ind]?.[getKeyByValue(formdata.component.defaultLoad.sMapping, child.inputtable.sColumnID)]} />
                              </>
                            )}
                      
                              {child.component.sType == "VARNUMBER" && (
                              <>
                              <VARNUMBER data={child} value={tableRecords[ind]?.[child.component.sName] || tableRecords[ind]?.[getKeyByValue(formdata.component.defaultLoad.sMapping, child.inputtable.sColumnID)]} varValue={varValue} format={format} />
                              </>
                            )}
                              

                          </TableCell>
                        </>
                      )
                    );
                  });
                })()}
              </TableRow>
            ))}

            {/* {
                        formdata?.child.map(child => child.inputtable?.bHidden != 1 && <TableCell > { renderComponent([child]) } </TableCell> )
                      }
                      */}

            {/* ))} */}

            {/* {tableRecords[0]?.tabledetails !== undefined
            ? tableRecords[0]?.tabledetails?.map(row => (
                <TableRow>
                  {columns?.map(col => (
                    <TableCell {...col?.props}>{row[col?.field]}</TableCell>
                  ))}
                </TableRow>
              ))
            : null} */}
            {/* {tableRecords[0]?.tablesummary !== undefined
            ? tableRecords[0]?.tablesummary.map(row =>
                row.sSummaryID === "summ_subtotal" ? (
                  <TableRow>
                    <TableCell rowSpan={6} />
                    <TableCell colSpan={1} />
                    <TableCell>{row.sLabel}</TableCell>
                    <TableCell align="center">{row.sInputValue}</TableCell>
                    <TableCell align="center">{row.sValue}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={1} />
                    <TableCell>{row.sLabel}</TableCell>
                    <TableCell align="center">{row.sInputValue}</TableCell>
                    <TableCell align="center">{row.sValue}</TableCell>
                  </TableRow>
                )
              )
            : null} */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function VartextComp({ data, value, id,format,varValue }) {

  const formatedData = value != undefined ? sprintf(data.component.sDisplayFormat, value) : "";
  function addThousandSeparatorToNumber(numberString) {
    // Convert the extracted number to a string with two decimal places
    const formattedNumber = parseFloat(numberString).toFixed(2);

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = formattedNumber.split(".");

    // Add comma separators to the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the formatted number with the decimal part
    return `${formattedIntegerPart}.${decimalPart}`;
  }

  function formatAllNumbersInString(input) {
    // Use a regular expression to match all numbers in the string
    return input.replace(/-?\d+(\.\d+)?/g, match => addThousandSeparatorToNumber(match));
  }
  const styleFormat = format?.data?.records?.find(
    item => item?.sFieldName == data?.component?.sName && item?.sFieldValue == value
  );
  const parsedData = styleFormat ? JSON.parse(styleFormat?.sFieldFormat) : {};

  return (
    <>
    {/* {JSON.stringify(data?.component?.sName)}
    {JSON.stringify(data.component.sProps)} */}
    <Typography id={id} name={data.component.sName} {...data.component.sProps} {...parsedData}>
      {formatedData == "undefined" ? (
        ""
      ) : (
        <span dangerouslySetInnerHTML={{ __html: formatedData  }} />
        // <span dangerouslySetInnerHTML={{ __html: formatAllNumbersInString(formatedData) }} />

      )}
    </Typography>
    {/* {
       <Vartext data={data} varValue={varValue} format={format} />
    } */}
      </>
  );
}
