import React, { useEffect, useState } from "react";
import SingleImg from "./SingleImg";
import MuiltiImg from "./MuiltiImg";
import { serverAddress } from "../../config";
import { Global_Data } from "../../globalData/GlobalData";

function ImgComponent({
  setTextValue,
  formAction,
  textValue,
  value,
  imagesIdsFromMedia,
  setImagesIdsFromMedia,
  data,
  openStaticDialog,
  id
}) {
  const { token } = Global_Data();
  const [featuredImg, setFeaturedImg] = useState({});
  //   useEffect(() => {
  //     if (imagesIdsFromMedia.length == 0 && value) {
  //       setImagesIdsFromMedia([{ sName: data?.component?.sName, data: [value] }]);
  //     }
  //   }, [value]);
  function processData(dataString) {
    if (dataString) {
      // Split the string by comma
      const parts = dataString?.split(/,\s*/);
      // Initialize an object to store the result
      const result = {
        featured: null, // Set default featured value
        data: []
      };
      // Iterate over parts and populate the result object
      for (let i = 0; i < parts.length; i++) {
        const trimmedPart = parts[i].trim();
        // Check if the current part is the featured one
        if (trimmedPart.includes(":Featured")) {
          // Extract the featured part and remove ':Featured' suffix
          result.featured = trimmedPart.replace(":Featured", "");
        } else {
          result.data.push(trimmedPart);
        }
      }

      return result;
    }
  }

  async function setFeaturedImgsSrc(item) {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const response = await fetch(
        serverAddress + `/image/retrieve?imageid=${item}`,
        requestOptions
      );
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      setFeaturedImg({ src: objectURL, imgID: item });
      // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error; // Rethrow the error for handling elsewhere if needed
    }
  }

  useEffect(() => {
    if (data?.component?.options?.mode == "SINGLE") {
      if (imagesIdsFromMedia?.length == 0 && value) {
        if (setImagesIdsFromMedia) {
          setImagesIdsFromMedia([{ sName: data?.component?.sName, data: [value] }]);
        }
      }
    } else {
      const processedData = processData(value);
      if (processedData) {
        let arr = processedData?.data.filter(item => item !== "");
        if (setImagesIdsFromMedia) {
          setImagesIdsFromMedia([
            {
              sName: data?.component?.sName,
              data: processedData?.featured ? [...arr, processedData?.featured] : [...arr]
            }
          ]);
        }
        setFeaturedImgsSrc(processedData.featured);
      }
    }
  }, [value]);

  return (
    <>
   {/* {value}ii */}
   {/* {JSON.stringify(textValue)} */}
      {data?.component?.options?.mode === "SINGLE" ? (
        <SingleImg data={data} value={value} id={id} />
      ) : (
        <>
        
          <MuiltiImg data={data}  featuredImgIDs={featuredImg} imagesIdsFromMedia={imagesIdsFromMedia} textValue={textValue} id={id} />
        </>
      )}
    </>
  );
}

export default ImgComponent;
