import { Autocomplete, Button, Checkbox, Chip, IconButton, Paper, TextField } from "@mui/material";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import axios from "axios";
import Input from "@mui/material/Input";
import { baseURL } from "../../api";
import CustomAlert from "../AlertComponent/Alert";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Global_Data } from "../../globalData/GlobalData";
import { serverAddress } from "../../config";
import { vsprintf } from "sprintf-js";
import { Icon } from "../../utils/MuiIcons/Icon";

function sleep(duration) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const AutoCompleteComponent = ({
  data,
  autovaluedata,
  autoValue1,
  // useEffectData,
  autoValue,autoSelectObj,
  rowsdataFree,
  setselectedAUTOCOMPLETE,
  Automod,
  formaction,
  errors,
  setallAccountData,
  isDisabledTable,
  handleClickOpen,
  isSubmited,
  formIsSubmited,
  id,
  textValue1,
  selectAndAutocompleteSname,
  modalPrimaryKey,params, summary,
  mainData
}) => {
  const { token, textValue, setTextValue, mainFormData, modalActionTypeAndID, autoCompleteAPICheck, setAutoCompleteAPICheck,
    autoCompleteData, setAutoCompleteData, freeFormToDefault, setFreeFormToDefault } = Global_Data();
  const [autoValueData, setAutoValueData] = useState([]);
  const [fixedOptions, setFixedOptions] = useState([]);
  const [valueForFixed, setvalueForFixed] = useState([]);
  const [customError, setCustomError] = useState("");
  const [valueForBackend, setValueforbackend] = useState("");
  const [editauto, setEditauto] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function replaceUriParams(data, uri) {
    // Iterate over each key in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // Create a regular expression to match {key}
        const regex = new RegExp(`{${key}}`, "g");
        // Replace {key} with the corresponding value from data
        uri = uri?.replace(regex, data[key]);
      }
    }
    return uri;
  }

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      // event.preventDefault();
      event.stopPropagation();
    }
  };



  function replacePlaceholders2(uri, data) {
    const regex = /{([^}]+)}/g;
    const replacedUri = uri.replace(regex, (match, key) => {
      return key in data ? data[key] : match;
    });
    return replacedUri;
  }

  useEffect(() => {
    if(freeFormToDefault){
      setAuto("");
      setFreeFormToDefault(false);
    }
  }, [freeFormToDefault])

  useEffect(() => {
    let uri;
    if (data.data.sAction == "CASCADE") {
      // onTagsChange()
      // setTextValue((pre)=>({...pre,[data?.component?.sName]: textValue[data?.component?.sName]||""}))
   
      setTextValue((pre)=>({...pre,[data?.component?.sName]: ""}))

      uri = replaceUriParams(
        textValue,
        autoValue?.startsWith("/")
          ? `${baseURL}${autoValue}`
          : `${baseURL}/${replacePlaceholders2(autoValue, textValue)}`
      );

    } else {

      uri = replaceUriParams(
        textValue,
        autoValue?.startsWith("/")
          ? `${baseURL}${autoValue}`
          : `${baseURL}/${replacePlaceholders2(autoValue, textValue)}`
      );

    }
    // alert('called')

    if(autoCompleteAPICheck.includes(uri)){
      autoCompleteData?.forEach((field) => {
        if(field.uri === uri){
          setAutoValueData(field.data);
        }
      })
    }

    if(!autoCompleteAPICheck.includes(uri)){
      setAutoCompleteAPICheck((prev) => [...prev, uri]);
    axios
      .get(uri, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      })
      .then(result => {
        const newData = result?.data?.data?.records
          ? result?.data?.data?.records
          : result?.data.data
          ? result?.data.data
          : result?.data;
        if (setallAccountData) {
          setallAccountData(newData);
        }
        setAutoValueData(newData);
        setAutoCompleteData((prev) => [...prev, {uri: uri, data: newData}]);
      })
      .catch(() => {
        setCustomError("Something went Wrong");
        setAutoCompleteAPICheck([]);
      });
    }
    // axios
    //   .get(`${baseURL}${autoValue ? autoValue : autoValue1}`)
    //   .then(result => {
    //     const newData = result?.data.data ? result?.data.data : result?.data;
    //     // if (data?.data?.sAdd?.sAddMessage !== "" && data?.data?.sAdd?.sAddMessage !== undefined) {
    //     //   console.log(data?.data?.sAdd?.sAddMessage, "data?.data?.sAdd?.sAddMessage");
    //     //   newData.unshift({ type: "BUTTON", display: data?.data?.sAdd?.sAddMessage });
    //     // } 
    //     setAutoValueData(newData);
    //   })
    //   .catch(() => {
    //     setCustomError("Something went Wrong");
    //   });
    // alert(textValue[data?.data?.sDataAware.replace(/[{}]/g, '')]);
  }, [
    isSubmited,
    formIsSubmited,
    textValue.sYesNo,//textValue1?.[data?.component?.sName],
    textValue[data?.data?.sDataAware?.replace(/[{}]/g, "")],//textValue[data.component.sName]
    autoCompleteAPICheck, autoCompleteData
  ]);

  // useCallback(() => {
  //   // Handle click logic here
  //   alert('s')
  // }, [textValue[data?.data?.sDataAware?.replace(/[{}]/g, "")]])

  function extractKeysFromFormat(displayFormat) {
    // Regular expression to match content inside curly braces
    const regex = /\{(.*?)\}/g;

    // Extract matches from the display format
    const matches = displayFormat.match(regex);

    // Create an object with keys display1 and display2
    const result = {
      display1: matches && matches.length > 0 ? matches[0]?.replace(/[{}]/g, "") : null,
      display2: matches && matches.length > 1 ? matches[1]?.replace(/[{}]/g, "") : null
    };

    return result;
  }

  // Example usage:

  const [displayItems, setDisplayItems] = useState({});
  useEffect(() => {
    const displayFormat = data?.data?.sDisplayFormat;
    const resultObject = extractKeysFromFormat(displayFormat);
    setDisplayItems(resultObject);
    setValueforbackend(data?.data?.sValueField);
  }, [data]);

  function handleInventoryName(sInventoryID, feild, retnfeild) {
    const inventory = autoValueData?.find(item => item[feild] === sInventoryID);
    return inventory ? inventory[retnfeild] : "";
  }
  function handlesAccountName(sAccountCode) {
    const inventory = autoValueData?.find(item => item.sAccountCode === sAccountCode);
    return inventory ? inventory.sAccountName : "";
  }
  const [auto, setAuto] = useState({});

  const [autoMul, setAutoMul] = useState([]);

  function genrateValue(data, valueForBackend) {
    let val = "";
    for (let i = 0; i < data.length; i++) {
      val += val ? `,${data[i][valueForBackend]}` : data[i][valueForBackend];
    }
    return val;
  }
  const onTagsChange = (e, values = {}) => {
    // console.log(values[valueForBackend],'singleSelected34435555');
    if (values) {
      setAutoMul(values);
      if (setselectedAUTOCOMPLETE) {
        setselectedAUTOCOMPLETE(values);
      }
      // alert(JSON.stringify(valueForBackend))
      if (data?.component?.options?.mode == "MULTIVALUE") {
        autovaluedata(genrateValue(values, valueForBackend), values);
      } else {
        // autovaluedata(values);
        autovaluedata(values[valueForBackend], values);
      }
      // alert(JSON.stringify(values));
      // alert(JSON.stringify(valueForBackend));
      setAuto(values);
      setEditauto(values);
    } else {
      setAutoMul("");
      autovaluedata("", "");
      setAuto("");
      setEditauto("");
      if (setselectedAUTOCOMPLETE) {
        setselectedAUTOCOMPLETE({});
      }
    }
  };

  const onFixedChange = (e, values) => {
    setvalueForFixed([
      ...fixedOptions,
      ...values?.filter(option => fixedOptions.indexOf(option) === -1)
    ]);
  };

  function extractDisplayValues(inputString) {
    const regex = /\{([^}]+)\}/g;
    const matches = [];
    let match;
    while ((match = regex.exec(inputString)) !== null) {
      matches.push(match[1]);
    }
    const displayObject = {};
    matches.forEach((match, index) => {
      const displayKey = `display${index + 1}`;
      displayObject[displayKey] = match;
    });
    return displayObject;
  }

  useEffect(() => {
    if (isSubmited) {
      // setAuto({sAccountName:"", sInventoryID: " ", sInventoryName: " " ,sAccountCode:" "});
      setAutoMul([]);
    }
  }, [isSubmited]);

  useEffect(() => {
    if (data?.component?.sDefaultValue && data?.component?.sDefaultValue !== "") {
      autoValueData && autoValueData.length > 0 && autoValueData?.map(item => {
        if (item?.value === data?.component?.sDefaultValue) {
          setAuto(item);
          setEditauto(item);
          setAutoMul([item]);
          setFixedOptions([item]);
          setvalueForFixed([item]);
        }
      });
    }
    if (rowsdataFree && rowsdataFree !== "") {
      autoValueData && autoValueData.length > 0 && autoValueData?.map(item => {
        if (item.value === rowsdataFree) {
          setEditauto(item);
        }
      });
    }
  }, [autoValueData, textValue]);
  // console.log(auto, "autp5555");

  useEffect(() => {
    autoValueData && autoValueData.length > 0 && autoValueData?.map(item => {
      if (textValue?.[data?.component?.sName] && modalActionTypeAndID.type === "edit" || textValue1?.[data?.component?.sName]) {
       
        if (item?.[data?.data?.sValueField] == textValue1?.[data?.component?.sName]) {
          setAuto(item);
          setEditauto(item);
          // useEffectData(textValue1?.[data?.component?.sName],item)
          setAutoMul([item]);
          setFixedOptions([item]);
          setvalueForFixed([item]);
          // if (autoSelectObj) {
          //   autoSelectObj(item)
          // }
        }
      } else {
        // console.log('briya',item,textValue1)
        
        if (item?.[data?.data?.sValueField] == textValue1?.[data?.component?.sName]) {
          // alert(JSON.stringify(textValue1?.[data?.component?.sName])) 
          setAuto(item);
          setEditauto(item);
          setAutoMul([item]);
          // autoValueData(textValue1?.[data?.component?.sName],item)
          // useEffectData(textValue1?.[data?.component?.sName],item)

          setFixedOptions([item]);
          setvalueForFixed([item]);
         
          return;
        } else {
          if (textValue1?.[data?.component?.sName] == "") {
            // setAuto("");

            // alert(JSON.stringify(textValue1))
            // setEditauto("");
          }
          // alert('dwd')

          // setAutoMul([item]);
        }
      }

      // if (item[data?.component?.sName] === textValue1[data?.component?.sName]) {
      //   if  (textValue?.[data?.component?.sName] && modalActionTypeAndID.type === 'edit') {
      //     if (item.sAccountID == textValue1.sAccountID) {
      //       console.log(item, "singleSelected344");
      //       setAuto(item)
      //       setAutoMul([item]);
      //       setFixedOptions([item]);
      //       setvalueForFixed([item]);
      //     }
      //   }
      // }
    });
  }, [valueForBackend, autoValueData, data, textValue1,textValue1?.[data?.component?.sName] ,textValue?.[data?.component?.sName]] );

  useLayoutEffect(()=>{
    if (autoSelectObj) {
      autoSelectObj(auto)
    }
  },[auto])

  function checkParenthesesExist(str) {
    let openParenIndex = str.indexOf("(");
    let closeParenIndex = str.indexOf(")");
    return openParenIndex !== -1 && closeParenIndex !== -1;
  }
  function replaceTextValues(displayFormat, textValue) {
    return displayFormat?.replace(/\{([^}]+)\}/g, function (match, key) {
      return textValue?.[key] || ""; // Replace with the value from textValue or an empty string if not found
    });
  }

  function openModalforGroupAdd(data) {

    const mode = {
      options: {
        mode: "DEFAULT",
        handler: "handleDialog",
        dialog: data.data.sAdd.sAddForm,
        sDataSource: data.data.sDataSource,
        nested:true
      }
    };

    handleClickOpen("s", mode);
    selectAndAutocompleteSname({ name: data.component.sName, value: data?.data?.sValueField });
  }

  const [activityBTN, setActivityBTN] = useState(false);

  function fetchActivity() {
    if (data?.data?.sAdd?.sFormName && data?.data?.sAdd?.sAction) {
      const urlCapture =
        serverAddress +
        `/form/isallowed/transaction?module=${data?.data?.sAdd?.sFormName}&activity=${data?.data?.sAdd?.sAction}`;
      axios
        .get(urlCapture, {
          headers: {
            Authorization: `Bearer ${token}`
            // Other headers if needed
          }
        })
        .then(result => {
          if (result.data.data.Access != "No") {
            setActivityBTN(true);
          }
        })
        .catch(error => {
          console.error(error, "error456");
        });
    }
  }

  React.useEffect(() => {
    fetchActivity();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...auto]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // alert(JSON.stringify(data.data))
  // alert(JSON.stringify(newData))
  // alert(JSON.stringify(data.data.sDisplayFormat))
  // alert(JSON.stringify(data.data.sDisplayFormat))

  // const newData = vsprintf(data.data.sDisplayFormat,data.data.sDisplayField.replace(/[{}]/g, '').split(','))
  // alert(JSON.stringify(newData.replace(/[{}]/g, '').split(',')))
  function replacePlaceholders(uri, data) {
    // Regular expression to match placeholders like {placeholderName}
    const placeholderRegex = /{([^}]+)}/g;

    // Replace placeholders in the uri with values from data
    const replacedUri = uri?.replace(placeholderRegex, (match, placeholder) => {
      // Check if the placeholder exists in the data object
      if (data.hasOwnProperty(placeholder)) {
        // Replace placeholder with corresponding value from data
        return data[placeholder];
      } else {
        // If placeholder doesn't exist in data, return the original placeholder
        return match;
      }
    });

    return replacedUri;
  }

  const removeBorder = data?.component?.options?.textFieldProps?.removeBorder || false;
  
  function convertToNumber(arr) {
    return arr.map(item => {
        const num = Number(item);
        return isNaN(num) ? item : num;
    });
}

  return (
    <>
      {/* {JSON.stringify(textValue1[data.component.sName])} */}

      {/* {textValue[data.component.sName]} */}
      {customError !== "" && <CustomAlert severity="error" message={customError} />}
      {formaction == undefined || formaction == "" || formaction == "ADD" ? (
        <>
          {data?.component?.options?.mode === "MULTIVALUE" &&
          data?.component?.options?.submode === "FIXEDOPTIONS" &&
          valueForFixed !== "" ? (
            <>
              <Autocomplete
                isOptionEqualToValue={(option, value) =>
                  option[valueForBackend] == value[valueForBackend]
                }
                //
                //
                multiple={data?.component?.options?.mode === "MULTIVALUE" ? true : false}
                id={data?.component?.sName}
                name={data?.component?.sName}
                options={autoValueData ? autoValueData : []}
                onChange={onFixedChange}
                disableCloseOnSelect={
                  data?.component?.options?.mode === "MULTIVALUE" &&
                  data?.component?.options?.submode === "CHECKBOXES"
                    ? true
                    : false
                }
                getOptionLabel={option =>
                  option && option != "" && Object.keys(option).length !== 0
                    ? vsprintf(
                        data.data.sDisplayFormat,
                        replacePlaceholders(data.data.sDisplayField, option)
                          ?.replace(/[{}]/g, "")
                          .split(",")
                      )
                    : ""
                }
                value={valueForFixed}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue?.map((option, index) => {
                    return (
                      <Chip
                        key={index}
                        label={option.sInventoryID + " - " + `(${option.sInventoryName})`}
                        {...getTagProps({ index })}
                        disabled={fixedOptions.indexOf(option) !== -1}
                      />
                    );
                  });
                }}
                renderInput={params => {
                  const { inputProps, ...restParam } = params;
                  const { value, ...restinputProps } = inputProps;
                  return (
                    <TextField
                      {...restParam}
                      onKeyDown={(e) => {
                        e.key === " " && e.stopPropagation();
                      }}
                      {...restinputProps}
                      value={value.replace(/<[^>]+>/g, "")}
                      name={data?.component?.sName}
                      id={data?.component?.sName}
                      label={data?.component?.sLabel}
                      placeholder={data?.component?.sPlaceHolder}
                      helperText={errors || data?.component?.sHelper}
                      error={errors}
                      sx={
                        removeBorder
                          ? {
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none"
                                }
                              },
                              "& .MuiInputBase-root": {
                                padding: "0px"
                              }
                            }
                          : {}
                      }
                      {...data?.component?.options?.textFieldProps}
                      // sx={{ border: '0px',outline: 'none',}}
                    />
                  );
                }}
                {...data?.component?.sProps}
              />
            </>
          ) : (
            <>
              {/* // for defalut */}

              {data?.data?.sGroupField !== "" && data?.component?.options?.mode !== "MULTIVALUE" ? (
                <>
                {/* {JSON.stringify(auto)} */}
                <Autocomplete
                    //isOptionEqualToValue={(option, value) => option[valueForBackend] == value[valueForBackend]}
                    multiple={data?.component?.options?.mode === "MULTIVALUE" ? true : false}
                    id={summary ? `${mainData}-${summary}` : id ? `${data?.component?.sName}-${id}` : data?.component?.sName}
                    name={data?.component?.sName}
                    loading={loading}
                    options={autoValueData ? autoValueData : []}
                    onChange={onTagsChange}
                    PaperComponent={({ children }) => {
                      return (
                        <>
                          <Paper>
                            {data?.data.sAdd.sAction == "ADD" && (
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2, mt: 2 }}
                                // onClick={e => handleClickOpen(e, data.data)}
                                onMouseDown={e => openModalforGroupAdd(data)}
                                // onMouseDown={e => handleClickOpen(e, data.data)}
                                disabled={!activityBTN}
                              >
                                <Icon
                                  iconName={data?.data?.sAdd?.sAddIcon}
                                  sProps={data?.data?.sAdd?.sIconProps}
                                />{" "}
                                {data?.data?.sAdd?.sAddMessage}
                              </Button>
                            )}
                            {children}
                          </Paper>
                        </>
                      );
                    }}
                    groupBy={option => option[data?.data?.sGroupField]}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <>
                          {/* {JSON.stringify(option)} */}
                          <div
                            {...props}
                            dangerouslySetInnerHTML={{
                              __html:
                                Object.keys(option).length !== 0
                                  ? vsprintf(
                                      data.data.sDisplayFormat,
                                      replacePlaceholders(data.data.sDisplayField, option)
                                        ?.replace(/[{}]/g, "")
                                        .split(",")
                                    )
                                  : ""
                            }}
                          />
                        </>
                      );
                    }}
                    disableCloseOnSelect={
                      data?.component?.options?.mode === "MULTIVALUE" &&
                      data?.component?.options?.submode === "CHECKBOXES"
                        ? true
                        : false
                    }
                    getOptionLabel={option =>
                      option && option != "" && Object.keys(option).length !== 0
                        ? vsprintf(
                            data.data.sDisplayFormat,
                            replacePlaceholders(data.data.sDisplayField, option)
                              ?.replace(/[{}]/g, "")
                              .split(",")
                          )
                        : ""
                    }
                    value={auto}
                    renderInput={params => {
                      const { inputProps, ...restParam } = params;
                      const { value, ...restinputProps } = inputProps;

                      return (
                        <TextField
                          name={data?.component?.sName}
                          id={data?.component?.sName}
                          {...restParam}
                          {...restinputProps}
                          value={value.replace(/<[^>]+>/g, "")}
                          label={data?.component?.sLabel}
                          placeholder={data?.component?.sPlaceHolder}
                          helperText={errors || data?.component?.sHelper}
                          error={errors}
                          onKeyDown={(e) => {
                          e.key === " " && e.stopPropagation();
                        }}
                          disabled={isDisabledTable}
                          {...data?.component?.options?.textFieldProps}
                          // {...data?.component?.options?.InputProps}

                          sx={
                            removeBorder
                              ? {
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "none"
                                    }
                                  },
                                  "& .MuiInputBase-root": {
                                    padding: "0px"
                                  }
                                }
                              : {}
                          }
                        />    
                      );
                    }}
                    {...data?.component?.sProps}
                    disabled={isDisabledTable}
                  />
            </>
              ) : (
                <>
                {/* {JSON.stringify(auto)}---
                {textValue[data?.component?.sName]} */}
                      {/* {console.log(auto?.hasOwnProperty('sInventoryID') ,776688)} */}
                      {/* {JSON.stringify(auto)} */}
                      {/* {data.data.sDisplayFormat} */}
                      {/* {vsprintf('[%s] %.2f', ['name', 12])} */}
                  <Autocomplete
                    //  isOptionEqualToValue={(option, value) => option[valueForBackend] == value[valueForBackend]}
                    {...data?.component?.sProps}
                    // sx={{ width: 300 }}
                    // fullWidth={false}
                    name={data?.component?.sName}
                    multiple={data?.component?.options?.mode === "MULTIVALUE" ? true : false}
                    id={summary ? `${mainData}-${summary}` : textValue1?.id ? `${data?.component?.sName}-${textValue1?.id}` : id ? `${data?.component?.sName}-${id}` : data?.component?.sName}
                    options={autoValueData ? autoValueData : []}
                    // fullWidth
                    onChange={onTagsChange}
                    onKeyDown={handleKeyDown}
                    disableCloseOnSelect={
                      data?.component?.options?.mode === "MULTIVALUE" &&
                      data?.component?.options?.submode === "CHECKBOXES"
                        ? true
                        : false
                    }
                    PaperComponent={({ children }) => {
                      return (
                        <>
                          <Paper>
                            {data?.data.sAdd.sAction == "ADD" && (
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2, mt: 2 }}
                                // onClick={e => handleClickOpen(e, data.data)}
                                onMouseDown={e => openModalforGroupAdd(data)}
                                disabled={!activityBTN}
                                // onMouseDown={e => handleClickOpen(e, data.data)}
                              >
                                <Icon
                                  iconName={data?.data?.sAdd?.sAddIcon}
                                  sProps={data?.data?.sAdd?.sIconProps}
                                />{" "}
                                {data?.data?.sAdd?.sAddMessage}
                              </Button>
                            )}
                            {children}
                          </Paper>
                        </>
                      );
                    }}
                    getOptionLabel={
                      option =>
                        option && option != "" && Object.keys(option).length !== 0
                      ? vsprintf(
                        data.data.sDisplayFormat,
                        replacePlaceholders(data.data.sDisplayField, option)
                          ?.replace(/[{}]/g, "")
                          .split(",")
                      )
                    : ""
                      // alert(JSON.stringify(newData.replace(/[{}]/g, '').split(',')))]
                    }
                    value={
                      data?.component?.options?.mode === "MULTIVALUE"
                        ? autoMul
                        : auto !== ""
                        ? auto
                        : ""
                    }
                    // isOptionEqualToValue={(option, value) =>  option[displayItems.display2 ] === value[displayItems.display2 ]}

                    renderOption={
                      data?.component?.options?.mode === "MULTIVALUE" &&
                      data?.component?.options?.submode === "CHECKBOXES"
                        ? (props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />

                              {/* {option.sInventoryID
                                ? option.sInventoryID + " - " + option.sInventoryName
                                : option.value + " - " + option.display} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    option && option != "" && Object.keys(option).length !== 0
                                      ? vsprintf(
                                          data.data.sDisplayFormat,
                                          replacePlaceholders(data.data.sDisplayField, option)
                                            ?.replace(/[{}]/g, "")
                                            .split(",")
                                        )
                                      : ""
                                }}
                              />
                            </li>
                          )
                        : (props, option, { selected }) => (
                            <li {...props}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    option && option != "" && Object.keys(option).length !== 0
                                    ? vsprintf(
                                      data.data.sDisplayFormat,
                                      replacePlaceholders(data.data.sDisplayField, option)
                                        ?.replace(/[{}]/g, "")
                                        .split(",")
                                    )
                                  : ""
                                }}
                              />
                            </li>
                          )
                    }
                    renderInput={params => {
                      const { inputProps, ...restParam } = params;
                      const { value, ...restinputProps } = inputProps;

                      return (
                        <>
                          {/* {JSON.stringify({params})} */}
                          <TextField
                            id={data?.component?.sName}
                            onKeyDown={(e) => {
                              e.key === " " && e.stopPropagation();
                            }}
                            name={data?.component?.sName}
                            disabled={isDisabledTable || data?.component?.sProps?.disabled}
                            {...restParam}
                            {...restinputProps}
                            value={value.replace(/<[^>]+>/g, "")}
                            label={data?.component?.sLabel}
                            placeholder={data?.component?.sPlaceHolder}
                            helperText={errors || data?.component?.sHelper}
                            error={errors}
                            sx={
                              removeBorder
                                ? {
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        border: "none"
                                      }
                                    },
                                    "& .MuiInputBase-root": {
                                      padding: "0px"
                                    }
                                  }
                                : {}
                            }
                            // InputProps={{
                            //   ...params.InputProps,
                            //   startAdornment: params.inputProps.value ? (
                            //     <div component="span" style={{width:"100%"}} dangerouslySetInnerHTML={{ __html: JSON.stringify('params')}} />
                            //   ) : null,
                            // }}
                            {...data?.component?.options?.textFieldProps}
                            defaultValue={params.InputProps.value}
                          />
                        </>
                      );
                    }}
                    {...data?.component?.sProps}
                    disabled={isDisabledTable || data?.component?.sProps?.disabled}
                  />
                  {/* {JSON.stringify(isDisabledTable)}d */}
                </>
              )}
            </>
          )}
        </>
      ) : null}
      {formaction === "EDIT" && Automod === "FREEFORM" ? (
        <>
          <Autocomplete
            isOptionEqualToValue={(option, value) =>
              option[valueForBackend] == value[valueForBackend]
            }
            disabled={isDisabledTable}
            multiple={data?.component?.options?.mode === "MULTIVALUE" ? true : false}
            id={data?.component?.sName}
            name={data?.component?.sName}
            options={autoValueData ? autoValueData : []}
            onChange={onTagsChange}
            PaperComponent={({ children }) => {
              return (
                <>
                  <Paper>
                    {data?.component?.options?.mode === "ADD" && (
                      <Button
                        color="primary"
                        fullWidth
                        sx={{ justifyContent: "flex-start", pl: 2, mt: 2 }}
                        // onClick={e => handleClickOpen(e, data.data)}
                        // onMouseDown={e => handleClickOpen(e, data.data)}
                        onMouseDown={e => openModalforGroupAdd(data)}
                        disabled={!activityBTN}
                      >
                        <Icon
                          iconName={data?.data?.sAdd?.sAddIcon}
                          sProps={data?.data?.sAdd?.sIconProps}
                        />{" "}
                        {data?.data?.sAdd?.sAddMessage}
                      </Button>
                    )}
                    {children}
                  </Paper>
                </>
              );
            }}
            groupBy={option => option[data?.data?.sGroupField]}
            disableCloseOnSelect={
              data?.component?.options?.mode === "MULTIVALUE" &&
              data?.component?.options?.submode === "CHECKBOXES"
                ? true
                : false
            }
            getOptionLabel={option =>
              option && option != "" && Object.keys(option).length !== 0
                ? vsprintf(
                    data.data.sDisplayFormat,
                    replacePlaceholders(data.data.sDisplayField, option)
                      ?.replace(/[{}]/g, "")
                      .split(",")
                  )
                : ""
            }
            value={auto}
            renderOption={
              data?.component?.options?.mode === "ADD"
                ? (props, option, { selected }) => (
                    <>
                      <li {...props}>
                        {option.sInventoryID
                          ? option.sInventoryID + " - " + `(${option.sInventoryName})`
                          : option.sAccountCode && option.sAccountCode !== ""
                          ? option.sAccountName + " - " + `(${option.sAccountCode})`
                          : null}
                      </li>
                      {/* )} */}
                    </>
                  )
                : data?.component?.options?.mode === "MULTIVALUE" &&
                  data?.component?.options?.submode === "CHECKBOXES"
                ? (props, option, { selected }) => (
                    <>
                      {/* {console.log(option, 5588)} */}
                      {option.type === "BUTTON" ? (
                        <Button variant="text">
                          <SettingsIcon className="mx-3" /> {option.display}
                        </Button>
                      ) : (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.sInventoryID + " - " + option.sInventoryName}
                        </li>
                      )}
                    </>
                  )
                : null
            }
            //   renderOption={
            //     data?.component?.options?.mode === "MULTIVALUE" &&
            //     data?.component?.options?.submode === "CHECKBOXES"
            //       ? (props, option, { selected }) => (
            //         <>
            //         { console.log(option,5588)}
            //     { option.type === 'BUTTON' ? (
            //   <Button variant='outlined' >{option.display}</Button>
            // ):
            // <li {...props}>
            //             <Checkbox
            //               icon={icon}
            //               checkedIcon={checkedIcon}
            //               style={{ marginRight: 8 }}
            //               checked={selected}
            //             />
            //             {option.sInventoryID + " - " + option.sInventoryName}
            //           </li>
            //         }
            //           </>
            //         )
            //       : null}
            renderInput={params => {
              const { inputProps, ...restParam } = params;
              const { value, ...restinputProps } = inputProps;

              return (
                <TextField
                  name={data?.component?.sName}
                  onKeyDown={(e) => {
                    e.key === " " && e.stopPropagation();
                  }}
                  id={data?.component?.sName}
                  {...restParam}
                  {...restinputProps}
                  value={value.replace(/<[^>]+>/g, "")}
                  label={data?.component?.sLabel}
                  placeholder={data?.component?.sPlaceHolder}
                  helperText={errors || data?.component?.sHelper}
                  sx={
                    removeBorder
                      ? {
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none"
                            }
                          },
                          "& .MuiInputBase-root": {
                            padding: "0px"
                          }
                        }
                      : {}
                  }
                  error={errors}
                  {...data?.component?.options?.textFieldProps}
                />
              );
            }}
            {...data?.component?.sProps}
          />
        </>
      ) : formaction === "EDIT" && Automod === "DEFAULT" ? (
        <>
          {/* dd */}
          {/* {JSON.stringify(editauto)} */}
          <Autocomplete
            isOptionEqualToValue={(option, value) =>
              option[valueForBackend] == value[valueForBackend]
            }
            placeholder={data?.component?.sPlaceHolder}
            multiple={data?.component?.options?.mode === "MULTIVALUE" ? true : false}
            id={data?.component?.sName}
            options={autoValueData ? autoValueData : []}
            name={data?.component?.sName}
            value={editauto}
            onChange={onTagsChange}
            getOptionLabel={option =>
              option && option != "" && Object.keys(option).length !== 0
                ? vsprintf(
                    data.data.sDisplayFormat,
                    replacePlaceholders(data.data.sDisplayField, option)
                      ?.replace(/[{}]/g, "")
                      .split(",")
                  )
                : ""
            }
            // getOptionLabel={option =>  option.sInventoryID ? option.sInventoryID + " - " + option.sInventoryName : option.display ? option.display + " - " + option.value:""}
            filterSelectedOptions
            renderInput={params => {
              const { inputProps, ...restParam } = params;
              const { value, ...restinputProps } = inputProps;
              return (
                <TextField
                  id={data?.component?.sName}
                  onKeyDown={(e) => {
                    e.key === " " && e.stopPropagation();
                  }}
                  name={data?.component?.sName}
                  {...restParam}
                  {...restinputProps}
                  value={value.replace(/<[^>]+>/g, "")}
                  label={data?.component?.sLabel}
                  placeholder={data?.component?.sPlaceHolder}
                  helperText={errors || data?.component?.sHelper}
                  error={errors}
                  sx={
                    removeBorder
                      ? {
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none"
                            }
                          },
                          "& .MuiInputBase-root": {
                            padding: "0px"
                          }
                        }
                      : {}
                  }
                  {...data?.component?.options?.textFieldProps}
                />
              );
            }}
            {...data?.component?.sProps}
          />
        </>
      ) : null}
      {/* {JSON.stringify(data?.component?.options?.textFieldProps)} */}
    </>
  );
};

export default AutoCompleteComponent;
