import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Global_Data } from "../../globalData/GlobalData";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function DialogDrawer({ children }) {
  const { isDialogDrawerOpen, setDialogDrawerOpen ,varCustomDialogTitle} = Global_Data();

  const handleClickOpen = () => {
    setDialogDrawerOpen(true);
  };

  const handleClose = () => {
    setDialogDrawerOpen(false);
  };
  console.log("children", children);

  return (
    <React.Fragment>
      <Dialog
        open={isDialogDrawerOpen}
        onClose={handleClose}
        maxWidth={"md"}
        style={{ zIndex: "1205" }}
        PaperProps={{
          component: "form",
          onSubmit: event => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          }
        }}
      >
         <IconButton
            onClick={()=>{
              handleClose(false)
            }}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
        <DialogTitle>{varCustomDialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button type="submit">Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
