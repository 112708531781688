import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { serverAddress } from "../../config";
import { Global_Data } from "../../globalData/GlobalData";
import { Icon } from "../../utils/MuiIcons/Icon";

function SingleImg({ data, value, imagesIdsFromMedia, setImagesIdsFromMedia, id }) {
  const [imgsrc, setImgsrc] = useState(null);
  const { token } = Global_Data();
  const [imgErr, setImgErr] = useState(false);

  const GetImg = async (uri) => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    let url =uri

  if (value) {
    url= uri+value?.replace(/[, ]+/g, " ")?.trim()
    fetch(url, requestOptions)
        .then(response => {
          if (response.ok) {
              setImgErr(false);
            return response.blob();
          } else {
            setImgErr(true);
          }
        })
        .then(blob => {
          const objectURL = URL?.createObjectURL(blob);
          setImgsrc(objectURL);
        
        })
        .catch(error => {
          console.error("Error fetching image:", error);
        });
    }    
  };

  // const GetImg = async () => {
  //   const requestOptions = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
    
  //   // alert(JSON.stringify(value))
  //   try {
  //     const response = await fetch(serverAddress + data?.data?.sDataSource +value? value?.replace(/[, ]+/g, " ")?.trim():"", requestOptions);
      
  //     if (response.ok) {
  //       setImgErr(false);
  //       const blob = await response.blob();
        
  //       if (blob instanceof Blob) {
  //         const objectURL = URL.createObjectURL(blob);
  //         setImgsrc(objectURL);
  //       } else {
  //         console.error("Fetched data is not a Blob:", blob);
  //         setImgErr(true);
  //       }
  //     } else {
  //       setImgErr(true);
  //       console.error("Response not OK:", response.status, response.statusText);
  //     }
  //   } catch (error) {
  //     setImgErr(true);
  //     console.error("Error fetching image:", error);
  //   }
  // };
  
  useEffect(() => {
      GetImg(serverAddress+data?.data?.sDataSource);
  }, [value,data,serverAddress]);

  return (
    <>
    {/* {serverAddress+data?.data?.sDataSource } */}
      <Grid {...data.grid_props}>{ imgErr ? <Icon iconName={data.component.options.sFailedIcon.sIcon}/> : <img className="nomax-widh" style={data.component.sProps} id={`${data?.component?.sName}-${id}`} src={imgsrc}  />}</Grid>
    </>
  );
}

export default SingleImg;
