import { Grid, Typography } from "@mui/material";
import * as MUIICon from "@mui/icons-material";

import axios from "axios";

import React, { useEffect, useState } from "react";

import { sprintf } from "sprintf-js";

import { baseURL } from "../../api";
import { serverAddress } from "../../config";
import { Global_Data } from "../../globalData/GlobalData";

function VARNUMBER({ data, varValue, format, dataTableValue, value }) {
  const { token, textValue, setTextValue, mainFormData, modalActionTypeAndID } = Global_Data();

let varNumberValueData = value  ? value : data?.data?.sAction == 'CASCADE'? textValue?.[data?.component?.sName] : varValue?.[data?.component?.sName]

  const styleFormatData = format?.data?.records?.find(
    item =>
      item?.sFieldName == data?.component?.sName &&
      item?.sFieldValue == varNumberValueData
  );
  const Icons = MUIICon[styleFormatData?.sStatusIcon];
  const styleFormat = format?.data?.records?.find(
    item =>
      item?.sFieldName == data?.component?.sName &&
      item?.sFieldValue == varNumberValueData
  );
  const parsedData = styleFormat ? JSON.parse(styleFormat?.sFieldFormat) : {};

  //     const [activityBTN,setActivityBTN] = useState(false)

  //   function fetchActivity() {
  //     const urlCapture =
  //       serverAddress+
  //       `/form/isallowed/transaction?module=${mainFormData.form.sFormName}&activity=${mainFormData.form.sFormAction}`;
  //     axios
  //       .get(urlCapture, {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //           // Other headers if needed
  //         }
  //       })
  //       .then(result => {

  //         if (result.data.data.Access == "No") {
  //           setActivityBTN(true);
  //         }
  //       })
  //       .catch(error => {
  //         console.error(error, "error456");
  //       });
  //   }

  //   React.useEffect(() => {
  //     fetchActivity();
  //   }, []);

  let isPositive = Number(varNumberValueData || dataTableValue) >= 0;
  const formatedData = varNumberValueData
    ? sprintf(
        isPositive ? data.component.sPositiveFormat : data.component.sNegativeFormat,
       ( varNumberValueData||dataTableValue)
      )
    : "";

  function addThousandSeparatorToNumber(numberString) {
    // Convert the extracted number to a string with two decimal places
    const formattedNumber = parseFloat(numberString).toFixed(2);

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = formattedNumber.split(".");

    // Add comma separators to the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the formatted number with the decimal part
    if (data?.component?.iDecimalPlaces == 0) {
      return `${formattedIntegerPart}`;
    }
    if (data.component.iDecimalPlaces == 1) {
      return parseFloat(`${formattedIntegerPart}.${decimalPart}`).toFixed(1);
    }
    if (data.component.iDecimalPlaces == 2) {
      return `${formattedIntegerPart}.${decimalPart}`;
    }
  }

  function formatAllNumbersInString(input) {
    // Use a regular expression to match all numbers in the string
    return input.replace(/-?\d+(\.\d+)?/g, match => addThousandSeparatorToNumber(match));
  }

  let sxProps = isPositive
    ? data?.component?.sPositiveProps
    : data?.component?.sNegativeProps || {};


    function replaceUriParams(data, uri) {
        // Iterate over each key in the data object
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            // Create a regular expression to match {key}
            const regex = new RegExp(`{${key}}`, "g");
            // Replace {key} with the corresponding value from data
            uri = uri.replace(regex, data[key]);
          }
        }
        return uri;
      }
const [res,setres] = useState()
async function fetchData(){
    const processedURL = replaceUriParams(textValue,data.data.sDataSource);
    const isAccountToPresent = processedURL.match(/[?&]accountto=([^&]*)/);
    const isValid = !isAccountToPresent || (isAccountToPresent[1] !== '');
    if(!processedURL.includes('{') && processedURL && isValid){
      axios
      .get(`${baseURL}${replaceUriParams(textValue,data.data.sDataSource)}`, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      })
      .then(result => {
      //   alert(JSON.stringify());
      // setres(result?.data?.data?.mainrecord[data.data.sValueField])

      // console.log("jakshrle", result.data);
      // alert(JSON.stringify({[data.component.sName]:result?.data?.data?.records[0][data.data.sValueField]+""}))
        setTextValue((pre)=>({...pre,[data.component.sName]:result?.data?.data?.records[0][data.data.sValueField]+""}))
      })
      .catch(error => {
        // setGlobalError(error);
        console.log(error, "error");
      });
    }
}

useEffect(() => {

    fetchData()

},[textValue[data.data.sDataAware.replace('{','').replace('}','')]]);



  return (
    <Grid item {...data?.grid_props}>
      {/* {varNumberValueData} */}

      
      <Typography {...sxProps}>
        {/* {value} */}
        {styleFormatData?.sStatusIcon && <Icons />}
        {formatedData.includes(undefined)
          ? ""
          : <span dangerouslySetInnerHTML={{ __html: formatAllNumbersInString(formatedData) }} /> ||
            ""}
      </Typography>
    </Grid>
  );
}

export default VARNUMBER;
